import React from 'react';
import { styled } from '@mui/material/styles';
import HomeTopBar from './HomeTopBar';
import HomeFooter from '@/components/layout/AppFooter';
import type { AppFooterProps } from '@/components/layout/AppFooter';
import { NextSeo, type NextSeoProps } from 'next-seo';
import notification from '@bamboo/core-lib/components/Notification';
import OrganizationJsonLd from '@/lib/seo/OrganizationJsonLd';
import WebsiteJsonLd from '@/lib/seo/WebsiteJsonLd';
import JsonLdBuilder from '@/lib/seo/JsonLdBuilder';
import Router from 'next/router';

const Root = styled('section')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',
  padding: '0% 8%',
  [theme.breakpoints.down('lg')]: {
    padding: '3%',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '-60px',
    padding: '0',
  },
}));

const InfiniteLoading = styled('div')(() => ({
  '&:before': {
    content: "''",
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    zIndex: 2,
    background: '#ffffff',
    borderRadius: 8,
  },
  '&:after': {
    content: "''",
    height: '100vh',
    width: '100vw',
    borderRadius: 8,
    background: 'url(/assets/Infinity-0.9s-201px.gif)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: '#ffffff',
    position: 'fixed',
    top: 0,
    zIndex: 2000,
    backgroundSize: '100px',
    opacity: '.44',
  },
}));

export const DefaultMetaTags = [
  {
    property: 'og:locale',
    content: 'pt_BR',
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:site_name',
    content: 'BambooStock',
  },
  {
    property: 'article:publisher',
    content: 'https://facebook.com',
  },
  {
    property: 'og:image:width',
    content: '2560',
  },
  {
    property: 'og:image:height',
    content: '1440',
  },
  {
    name: 'twitter:card',
    content: 'summary_large_image',
  },
];

const SnackbarNotification = notification();

export type PaperbaseProps = React.HTMLProps<HTMLDivElement> & {
  seoProps?: NextSeoProps;
  foolerLabels?: AppFooterProps['labels'];
  disablePassThroughLoading?: boolean;
};
let t: NodeJS.Timeout;
function Paperbase(props: PaperbaseProps) {
  const { seoProps, as, foolerLabels, disablePassThroughLoading, ...others } =
    props;
  const [loading, setLoading] = React.useState(false);

  const seo: NextSeoProps | undefined = seoProps
    ? {
        ...seoProps,
        additionalMetaTags: [
          ...DefaultMetaTags,
          ...(seoProps?.additionalMetaTags ?? []),
        ],
      }
    : undefined;

  const startLoading = React.useCallback(() => {
    if (disablePassThroughLoading) {
      return;
    }
    t = setTimeout(() => {
      setLoading(true);
    }, 500);
  }, [disablePassThroughLoading]);

  const endLoading = React.useCallback(() => {
    clearTimeout(t);
    setLoading(false);
  }, []);

  React.useEffect(() => {
    Router.events.on('routeChangeStart', startLoading);
    Router.events.on('routeChangeComplete', endLoading);
    Router.events.on('routeChangeError', endLoading);
    return () => {
      Router.events.off('routeChangeStart', startLoading);
      Router.events.off('routeChangeComplete', endLoading);
      Router.events.off('routeChangeError', endLoading);
    };
  }, []);

  return (
    <>
      <NextSeo
        {...seo}
        noindex={process.env.NEXT_PUBLIC_ENVIRONMENT == 'homolog'}
        nofollow={process.env.NEXT_PUBLIC_ENVIRONMENT == 'homolog'}
      />
      <OrganizationJsonLd />
      <WebsiteJsonLd />
      <HomeTopBar />
      <Root {...others} />
      <HomeFooter labels={foolerLabels} />
      <SnackbarNotification />
      {loading ? <InfiniteLoading /> : null}
      <JsonLdBuilder />
    </>
  );
}

export default Paperbase;
