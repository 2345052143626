import Link from 'next/link';
import React from 'react';
import { styled } from '@mui/material/styles';

const Root = styled('section')(() => ({
  position: 'relative',
  borderStyle: 'solid',
  borderWidth: '1px 0px 0px',
  borderColor: 'rgb(231, 231, 231)',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  padding: '0% 8% 2%',
  marginTop: '50px',
  marginBottom: '50px',
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  marginRight: 'auto',
  marginLeft: 'auto',
  position: 'relative',
  maxWidth: '1800px',
  borderTop: '0px solid rgb(224, 223, 223)',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  flexWrap: 'wrap',
}));

const HelpSection = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  width: '64.593%',
  flexWrap: 'wrap',
  margin: '56px 0px 0px',
  padding: '0px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const HelpTitleContainer = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  textAlign: 'left',
  marginBottom: '0px',
  padding: '0px 0px 15px',
}));

const HelpTitle = styled('h2')(() => ({
  padding: '0px',
  margin: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  lineHeight: 1,
  color: 'rgb(7, 7, 7)',
  fontSize: '20px',
  fontWeight: 600,
}));

const HelpDescriptionContainer = styled('div')(() => ({
  textAlign: 'left',
  position: 'relative',
  width: '100%',
  marginBottom: '0px',
  margin: '-11px 0px 0px',
}));

const HelpDescription = styled('span')(() => ({
  boxSizing: 'border-box',
  display: 'inline-block',
  color: 'rgb(147, 147, 147)',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '1.2em',
  whiteSpace: 'break-spaces',
}));

const HelpButtonContainer = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  margin: '14px 0px 0px',
}));

const HelpButtonLink = styled(Link)(() => ({
  boxSizing: 'border-box',
  padding: '12px 24px',
  transition: 'all 0.3s ease 0s',
  display: 'inline-block',
  lineHeight: 1,
  fontSize: '15px',
  color: 'rgb(255, 255, 255)',
  fill: 'rgb(255, 255, 255)',
  textAlign: 'center',
  textDecoration: 'none',
  boxShadow: 'none',
  fontWeight: 500,
  borderRadius: '100px',
  backgroundColor: 'rgb(0, 0, 0)',
}));

const InfoSection = styled('div')(({ theme }) => ({
  minHeight: '1px',
  position: 'relative',
  display: 'flex',
  width: '13.746%',
  flexWrap: 'wrap',
  margin: '56px 0px 0px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    width: '50%',
  },
}));

const TitleContainer = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  marginBottom: '10px',
  textAlign: 'left',
  padding: '0px 0px 15px',
}));

const Title = styled('h2')(() => ({
  padding: '0px',
  margin: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  lineHeight: 1,
  color: 'rgb(7, 7, 7)',
  fontSize: '12px',
  fontWeight: 600,
}));

const AboutSection = styled('div')(({ theme }) => ({
  minHeight: '1px',
  position: 'relative',
  display: 'flex',
  width: '11.398%',
  flexWrap: 'wrap',
  margin: '56px 0px 0px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    width: '50%',
  },
}));

const LicensingSection = styled('div')(({ theme }) => ({
  minHeight: '1px',
  position: 'relative',
  display: 'flex',
  width: '9.561%',
  flexWrap: 'wrap',
  margin: '56px 0px 0px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    width: '50%',
  },
}));

const List = styled('ul')(() => ({
  border: '0px none',
  outline: '0px',
  background: 'transparent',
  fontSize: '100%',
  verticalAlign: 'baseline',
  boxSizing: 'border-box',
  margin: '0px',
  listStyleType: 'none',
  marginTop: '0px',
  marginBottom: '0px',
  padding: '0px',
}));

const ListItem = styled('li')(() => ({
  border: '0px none',
  outline: '0px',
  background: 'transparent',
  verticalAlign: 'baseline',
  boxSizing: 'border-box',
  margin: '0px',
  padding: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  position: 'relative',
  display: 'flex',
  MozBoxAlign: 'center',
  alignItems: 'center',
  fontSize: 'inherit',
  MozBoxPack: 'start',
  justifyContent: 'flex-start',
  textAlign: 'left',
  paddingBottom: 'calc(3px)',
}));

const ListItemLink = styled(Link)(() => ({
  backgroundColor: 'transparent',
  boxSizing: 'border-box',
  textDecoration: 'none',
  boxShadow: 'none',
  display: 'flex',
  MozBoxAlign: 'center',
  alignItems: 'center',
  width: '100%',
  MozBoxPack: 'start',
  justifyContent: 'flex-start',
  textAlign: 'left',
  fontSize: '10px',
  fontWeight: 400,
  color: 'black',
}));

const DefaultLabels = {
  helpTitle: 'Precisa de ajuda?',
  helpDescription:
    'Não encontrou o clipe que procurava, encontrou algum problema?\nMande uma mensagem e deixa a gente te ajudar.',
  helpButtonLabel: 'Fale conosco',
  infoTitle: 'AJUDA E INFORMAÇÕES',
  infoProjects: 'Meus Projetos',
  infoAccount: 'Minha conta',
  infoFAQ: 'FAQs',
  infoHelp: 'Precisa de ajuda?',
  aboutTitle: 'SOBRE A BAMBOO',
  aboutWhoWeAre: 'Quem somos',
  aboutJobs: 'Jobs',
  licensingTitle: 'LICENCIAR UMA IMAGEM',
  licensingPlaylist: 'Playlists',
  licensingSearch: 'Pesquisar',
  instagram: 'instagram',
};

export type AppFooterProps = {
  labels?: Partial<typeof DefaultLabels>;
};

const AppFooter = (props: AppFooterProps) => {
  const { labels = DefaultLabels } = props;
  const infoLinks = React.useMemo(
    () => [
      { label: labels?.infoProjects, link: '/' },
      { label: labels?.infoAccount, link: '/' },
      { label: labels?.infoFAQ, link: '/faq' },
      {
        label: labels?.infoHelp,
        link: process.env.CONTACT_LINK ?? '',
        rel: 'noopener noreferrer',
        target: '_blank',
      },
    ],
    [labels]
  );

  const aboutLinks = React.useMemo(
    () => [
      {
        label: labels?.aboutWhoWeAre,
        link: '/discover',
      },
      {
        label: labels?.instagram,
        link: process.env.INSTAGRAM_URL ?? '',
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      { label: labels?.aboutJobs, link: '/jobs' },
    ],
    [labels]
  );

  const licensingLinks = React.useMemo(
    () => [
      { label: labels?.licensingPlaylist, link: '/playlists' },
      { label: labels?.licensingSearch, link: '/search' },
    ],
    [labels]
  );

  return (
    <Root>
      <Container>
        <HelpSection>
          <HelpTitleContainer>
            <HelpTitle>{labels?.helpTitle}</HelpTitle>
          </HelpTitleContainer>
          <HelpDescriptionContainer>
            <HelpDescription>{labels?.helpDescription}</HelpDescription>
          </HelpDescriptionContainer>
          <HelpButtonContainer>
            <HelpButtonLink
              href={process.env.CONTACT_LINK ?? ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              {labels?.helpButtonLabel}
            </HelpButtonLink>
          </HelpButtonContainer>
        </HelpSection>
        <InfoSection>
          <TitleContainer>
            <Title>{labels?.infoTitle}</Title>
          </TitleContainer>
          <List>
            {infoLinks.map((item, i) => (
              <ListItem key={i}>
                <ListItemLink
                  href={item.link}
                  rel={item?.rel}
                  target={item?.target}
                  style={{
                    lineHeight: 1.7,
                  }}
                >
                  {item?.label}
                </ListItemLink>
              </ListItem>
            ))}
          </List>
        </InfoSection>
        <AboutSection>
          <TitleContainer>
            <Title>{labels?.aboutTitle}</Title>
          </TitleContainer>
          <List>
            {aboutLinks.map((item, i) => (
              <ListItem
                key={i}
                style={{
                  lineHeight: 1.7,
                }}
              >
                <ListItemLink
                  href={item.link}
                  rel={item?.rel}
                  target={item?.target}
                >
                  {item?.label}
                </ListItemLink>
              </ListItem>
            ))}
          </List>
        </AboutSection>
        <LicensingSection>
          <TitleContainer>
            <Title>{labels?.licensingTitle}</Title>
          </TitleContainer>
          <List>
            {licensingLinks.map((item, i) => (
              <ListItem
                key={i}
                style={{
                  lineHeight: 1.7,
                }}
              >
                <ListItemLink href={item.link}>{item?.label}</ListItemLink>
              </ListItem>
            ))}
          </List>
        </LicensingSection>
      </Container>
    </Root>
  );
};

export default AppFooter;
