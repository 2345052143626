import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, useMediaQuery } from '@mui/material';
import HiddenComponent from '@bamboo/core-lib/src/components/HiddenComponent';
import Logo from '@bamboo/core-lib/components/Display/DefaultLogo';
import FavoriteIconButton from '@/components/layout/FavoriteIconButton';
import CartIconButton from '@/components/layout/CartIconButton';
import ProfileIconButton from '@/components/layout/ProfileIconButton';
import SideNavigation from '@/components/layout/TobarSideNavigation';
import { homeConfig } from '../home.config';
import { useTranslation } from 'next-i18next';
import Icons from '@bamboo/core-lib/components/Icons';

const Root = styled('section')(({ theme }) => ({
  position: 'relative',
  padding: '1% 10%',
  marginBottom: '-10px',
  backgroundColor: 'rgb(255, 255, 255)',
  color: 'rgb(232, 230, 227)',
  [theme.breakpoints.down('lg')]: {
    padding: '1% 3%',
  },
  [theme.breakpoints.down('md')]: {
    zIndex: 1,
    padding: '3% 4% 0%',
    backgroundColor: 'transparent',
  },
}));

const Container = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  marginRight: 'auto',
  marginLeft: 'auto',
  position: 'relative',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  maxWidth: '1800px',
  minHeight: '50px',
  color: 'rgb(232, 230, 227)',
}));

const ActionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  '& > *:nth-child(n+2)': {
    marginLeft: '10px',
  },
}));

const AppTopBar = () => {
  const { t } = useTranslation(homeConfig.i18nNamespaces);

  const [isSM, setIsSM] = React.useState(false);
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  React.useEffect(() => {
    setIsSM(isSmallerScreen);
  }, [isSmallerScreen]);

  return (
    <AppBar position="static" elevation={0} style={{ background: 'none' }}>
      <Root>
        <Container>
          <HiddenComponent hidden={!isSM}>
            <Logo width={250} fill="#ffffff" />
          </HiddenComponent>
          <ActionContainer>
            <HiddenComponent hidden={isSM}>
              <ProfileIconButton
                label={t('common:actions.toEnter')}
                alignLabel="left"
                labels={{
                  logoutDialog: {
                    accept: t('common:actions.yes'),
                    refuse: t('common:actions.no'),
                    text: t('common:logoutText'),
                  },
                }}
                styles={{
                  avatarContainer: {
                    marginBottom: '-7px',
                  },
                }}
              />
              <FavoriteIconButton
                dialogLabels={{
                  close: t('common:actions.close'),
                  loginText: t('navigation:authForm.title'),
                  onboardingDescription: t(
                    'navigation:authForm.onboarding_description'
                  ),
                  generic_login_error: t(
                    'navigation:authForm.generic_login_error'
                  ),
                  user_already_exists: t(
                    'navigation:authForm.user_already_exists'
                  ),
                  generic_onboarding_error: t(
                    'navigation:authForm.generic_onboarding_error'
                  ),
                  user_doesnt_exist: t('navigation:authForm.user_doesnt_exist'),

                  noAccount: t('navigation:authForm.no_account'),
                  hasAccount: t('navigation:authForm.has_account'),
                  create_account: t('navigation:authForm.create_account'),
                  onboardingText: t('navigation:authForm.onboarding_title'),
                  loginDescription: t('navigation:authForm.description'),
                  loginFormLabels: {
                    email_username: t('navigation:authForm.email_username'),
                    remember_me: t('navigation:authForm.remember_me'),
                    forgot_password: t('navigation:authForm.forgot_password'),
                    signin_submit: t('navigation:authForm.signin_submit'),
                    password: t('navigation:authForm.password'),
                  },
                  onboardingFormLabels: {
                    email: t('navigation:authForm.email_username'),
                    password: t('navigation:authForm.password'),
                    password_confirm: t(
                      'navigation:authForm.onboarding_confirm_password'
                    ),
                    user_name: t('navigation:authForm.onboarding_user_name'),
                    reading_terms: t(
                      'navigation:authForm.onboarding_reading_terms'
                    ),
                    terms: t('navigation:authForm.onboarding_terms'),
                    onboarding_submit: t(
                      'navigation:authForm.onboarding_submit'
                    ),
                    password_error: t(
                      'navigation:authForm.onboarding_password_error'
                    ),
                    email_error: t(
                      'navigation:authForm.onboarding_email_error'
                    ),
                  },
                  social_continue_with: t('auth:social.continue_with'),
                  signing_method_or: t('auth:form.signing_method_or'),
                }}
              />
              <CartIconButton
                labels={{
                  cartItens: (n) => t('common:cart.cartItens', { n }),
                  checkout: t('common:actions.checkout'),
                  total: t('common:cart.total'),
                  emptyCart: t('common:cart.emptyCart'),
                  search: t('common:cart.search'),
                  itemRemovedFromCart: t('common:cart.itemRemovedFromCart'),
                  failedToRemoveItemFromCart: t(
                    'common:cart.failedToRemoveItemFromCart'
                  ),
                }}
              />
            </HiddenComponent>
            <HiddenComponent hidden={!isSM}>
              <SideNavigation color="#ffffff" icon={<Icons.Menu />} />
            </HiddenComponent>
          </ActionContainer>
        </Container>
      </Root>
    </AppBar>
  );
};

export default AppTopBar;
